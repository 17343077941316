import React from 'react';
import Layout from '../components/layout';
import Button from '../components/layout/button';

const SuccessPage = () => {
  return (
    <Layout
      seo={{
        title: 'Sikeres',
        description:
          'Sikerélményt viszünk a fiatalok életébe szociális helyzettől függetlenül.'
      }}
    >
      <div className="bg-bgfirst ">
        <div className="conatiner flex justify-center text-center">
          <div className="max-w-lg mt-24 mb-20 p-5">
            <img
              alt="Succes"
              src="/succes.jpg"
              width=""
              className="mx-auto mt-10 mb-10 rounded-xl"
            />{' '}
            <h2 className="mb-10 mt-10 uppercase">Sikeres adományozás!</h2>
            <p className="mb-5 mt-5">
              Köszönjük a támogatást, a felajánlásodat a rendszer sikeresen
              feldolgozta. További teendőid nincsenek, ha szeretnél rendszeresen
              értesülni híreinkről és lehetőségeinkről kövess minket a közösségi
              oldalainkon!
            </p>
            <Button to="/" variant="primary" className="mb-10">
              VISSZA A FŐOLDALRA
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SuccessPage;
